// -- The Link Resolver
// This function will be used to generate links to Prismic documents
// As your project grows, you should update this function according to your routes

exports.linkResolver = function linkResolver(doc) {
  if (doc.type === 'page') {
    return `/page/${doc.uid}`
  }
  if (doc.type === 'produkte') {
    return `/produkt/${doc.uid}`
  }
  if (doc.type === 'entdeckungen') {
    return `/entdeckung/${doc.uid}`
  }
  // Homepage route fallback
  return '/';
}
