module.exports = [{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"besonder","path":"/preview","previews":true,"pages":[{"type":"Page","match":"/page/:uid","path":"/page-preview","component":"/opt/build/repo/src/templates/page.js"},{"type":"Produkte","match":"/produkt/:uid","path":"/produkt-preview","component":"/opt/build/repo/src/templates/produkt.js"},{"type":"Entdeckungen","match":"/entdeckung/:uid","path":"/entdeckung-preview","component":"/opt/build/repo/src/templates/entdeckung.js"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","display":"minimal-ui","icon":"src/images/favicon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-166252586-1","cookieName":"gatsby-gdpr-google-analytics","anonymize":true},"environments":["production","development"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
